<script setup>
// Imports
import { ref, computed, watch, useSlots } from 'vue'
import uuid from 'uuid/v4'
import AppPermissionTooltip from '@/components/app-permission-tooltip/app-permission-tooltip.vue'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import { fontBackgroundColor, fontColor } from './colors.js'

// Define the UploadAdapter class
class UploadAdapter {
  constructor(loader) {
    this.loader = loader
  }

  upload() {
    return new Promise((resolve, reject) => {
      // Ensure that the loader's file is a valid Blob
      const file = this.loader.file

      // Check if file is a promise
      if (file && typeof file.then === 'function') {
        file
          .then((file) => {
            const reader = new FileReader()

            reader.onload = () => {
              const base64String = reader.result.split(',')[1] // Get the base64 string
              resolve({
                default: `data:${file.type};base64,${base64String}` // Return the base64 URL
              })
            }

            reader.onerror = () => {
              reject('Failed to convert file to base64')
            }
            reader.readAsDataURL(file)
          })
          .catch((error) => {
            reject(`Failed to retrieve file: ${error}`)
          })
      } else {
        reject('Provided file is not valid.')
      }
    })
  }
}

// Define your props and emits
const slots = useSlots()
const props = defineProps({
  id: String,
  value: String,
  disabled: Boolean,
  readonly: Boolean,
  toolbar: {
    type: Array,
    default: () => []
  },
  appendToolbar: {
    type: Array,
    default: () => []
  },
  height: {
    type: String,
    default: '300px'
  },
  placeholder: {
    type: String,
    default: 'Start typing...'
  }
})

const emit = defineEmits(['input', 'ready'])

const wrapperEl = ref(null)
const editorData = ref('<p>The editor data</p>')

const defaultToolbar = ref({
  items: [
    'undo',
    'redo',
    'heading',
    'bold',
    'link',
    'italic',
    'fontBackgroundColor',
    'fontColor',
    'fontFamily',
    'fontSize',
    'removeHighlight',
    'highlight',
    'blockQuote',
    'numberedList',
    'bulletedList',
    'insertImage',
    'alignment',
    'indent',
    'outdent',
    'horizontalLine',
    'insertTable',
    'exportPdf'
  ]
})
// Custom upload adapter plugin
function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return new UploadAdapter(loader)
  }
}
const editorConfig = ref({
  placeholder: props.placeholder,
  fontColor,
  fontBackgroundColor,
  // eslint-disable-next-line
  toolbar: buildToolbar(),
  extraPlugins: [MyCustomUploadAdapterPlugin],
  allowedContent: true,
  table: {
    contentToolbar: [
      'tableColumn',
      'tableRow',
      'mergeTableCells',
      'tableProperties',
      'tableCellProperties'
    ]
  },
  image: {
    toolbar: [
      'imageTextAlternative',
      'toggleImageCaption',
      'imageStyle:inline',
      'imageStyle:block',
      'imageStyle:side',
      'linkImage'
    ]
  },
  list: {
    properties: {
      styles: true,
      startIndex: true,
      reversed: true
    }
  }
})

const isDirty = ref(false)
const EditorId = ref(props.id || `editor-${uuid()}`)

const isDisabled = computed(() => {
  return props.disabled || props.readonly
})

watch(
  () => props.value,
  (value) => {
    if (editorData.value === value) return
    editorData.value = value
  },
  { immediate: true }
)

watch(
  () => editorData.value,
  (value) => {
    if (props.value === value) {
      return
    }
    emit('input', value)
    if (!isDirty.value) isDirty.value = true
  }
)

function resizeEditor() {
  const editorEl = wrapperEl.value.querySelector(
    '.ck-editor__editable_inline:not(.ck-comment__input *)'
  )

  if (editorEl) {
    editorEl.style = `height: ${props.height}px;`
  }
}

function onEditorReady() {
  emit('ready')
  resizeEditor()
}

function buildToolbar() {
  const toolbarItems = props.toolbar.length ? props.toolbar : defaultToolbar.value.items
  if (props.appendToolbar) {
    toolbarItems.push(...props.appendToolbar)
  }
  return { items: toolbarItems }
}

function getPlainText() {
  const span = document.createElement('span')
  span.innerHTML = this.value
  return span.textContent || span.innerText
}

defineExpose({
  getPlainText
})
</script>

<template>
  <div ref="wrapperEl" class="app-editor">
    <div v-if="isDisabled" class="editor-locked-icon">
      <AppPermissionTooltip class="permission-icon has-margin-left-tiny" />
    </div>

    <ckeditor
      :id="EditorId"
      v-model="editorData"
      :editor="Editor"
      :config="editorConfig"
      :disabled="isDisabled"
      :style="{ height: props.height + 'px' }"
      @ready="onEditorReady"
    >
    </ckeditor>

    <div
      v-if="slots['append'] && isDirty"
      :class="{ 'is-visible': hasAppend && isDirty }"
      class="app-editor-append"
    >
      <slot ref="append" name="append"></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.app-editor {
  position: relative;
  margin-top: $gap;

  :deep(.ck-editor__editable_inline:not(.ck-comment__input *)) {
    resize: both;
    min-height: 300px;
    max-height: 600px;
    height: auto;
    overflow-y: auto;
  }

  :deep(.ck-editor__editable) {
    padding: $gap-tiny;
    border: 1px solid $border;
    background: $white;
    color: $text;
    transition: border-color 0.2s;
    max-height: 600px;
    overflow-y: auto;
    resize: vertical;
  }

  :deep(.ck-read-only) {
    background: $white-bis !important;
  }

  :deep(.ck.ck-powered-by) {
    display: none !important;
  }

  &-append {
    padding: 0;
    &.is-visible {
      display: block;
      padding: $gap-tiny;
      color: $c-danger;
    }
  }

  .editor-locked-icon {
    position: absolute;
    left: $gap-tiny;
    bottom: $gap-tiny;
    color: $c-text-light;
    z-index: $z-4;

    i,
    i:before {
      font-size: 16px;
    }
  }
}
</style>

<style lang="scss">
.ck.ck-powered-by {
  /* not cool, guys */
  display: none !important;

  .ck.ck-toolbar {
    background: none;
  }

  // editor styles
  .ck-editor__top {
    border-bottom: 0;
    background: $white;
    border-radius: $radius $radius 0 0;

    .cke_button {
      border-radius: 4px;
      border-color: transparent;

      &.cke_button_on {
        background: $c-border;
        color: $white;
      }
    }
  }
}
.ck-editor__editable {
  overflow-y: auto !important;
  resize: both;
  max-height: 300px;
}

.ck-table-resized {
  border: 1px solid hsl(0deg 0% 0%);
}

.app-editor .ck-editor__editable {
  border: 1px solid hsl(0deg 0% 0%);
}
.ck-editor__editable .ck-editor__nested-editable {
  overflow: hidden !important;
  resize: none !important;
}
</style>
