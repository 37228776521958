<template>
  <app-grid class="buyer-dashboard-overview__projects-ending has-margin-top">
    <div slot="actionsRight">
      <app-grid-pagination
        :page-size="gridPageSize"
        :current-page="gridCurrentPage"
        :total-pages="gridTotalPages"
        :total-rows="gridTotalRows"
        @clickPrev="handleClickPrev"
        @clickNext="handleClickNext"
        @pageSizeChange="handleGridPageSizeChange"
      >
      </app-grid-pagination>
      <label class="has-padding-right">{{ $t(viewString + 'labelContractsClosing') }}</label>
      <el-dropdown
        :show-timeout="0"
        :hide-timeout="0"
        trigger="click"
        class="has-margin-right-small"
        @command="handleSelectContractClosingRange"
      >
        <app-button>
          <label class="has-padding-right is-inline-block"
            >{{ daysUntilExpired | capitalize }} {{ contractDaysString }}</label
          >
          <app-icon icon="chevron-down"> </app-icon>
        </app-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="(days, index) in contractClosingRanges"
            :key="'selected-bid-range' + index"
            :command="days"
          >
            {{ days > 1 ? days + ' ' + $t('ui.common.days') : $t(viewString + 'statusExpired') }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown :show-timeout="0" :hide-timeout="0" :hide-on-click="false" trigger="click">
        <app-column-button />
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="(column, index) in columnDefs" :key="'visible-column' + index">
            <app-checkbox
              :id="column.field"
              v-model="column.visible"
              :label="column.columnHeading"
              :name="column.field"
              :value="column.field"
              class="has-margin-bottom-tiny"
            >
            </app-checkbox>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-table
      ref="bidTable"
      :data="tableData"
      class="table-bids"
      width="100%"
      stripe
      @sort-change="handleColumnReorder"
    >
      <span slot="empty">{{ $t('ui.views.dashboard.dashboard-view.overviewEndingEmpty') }}</span>
      <el-table-column
        v-for="(column, c) in visibleColumns"
        :key="`column-${c}`"
        :label="column.columnHeading"
        :align="getAlign(column.field)"
        :min-width="column.width || '150'"
        :property="column.field"
        :sortable="
          column.field === 'projectName' ||
          column.field === 'projectNumber' ||
          column.field === 'expiresDate'
        "
      >
        <template slot-scope="props">
          <span v-if="column.field === 'projectNumber'">
            {{ props.row[column.field] }}
          </span>
          <router-link
            v-if="column.field === 'projectName'"
            :to="`/projects/${props.row.projectId}/contract`"
          >
            {{ props.row[column.field] }}
          </router-link>
          <span v-if="column.field === 'type'">
            <span v-if="props.row.type === true">{{ $t(viewString + 'TypeTerm') }}</span>
            <span v-else>{{ $t(viewString + 'TypeNoTerm') }}</span>
          </span>
          <span v-if="column.field === 'supplier'">
            <ul v-if="props.row[column.field].length">
              <li
                v-for="(supplier, index) in props.row.supplier"
                :key="`column-${index}`"
                class="has-padding-bottom-small"
              >
                {{ supplier.companyName }}
              </li>
            </ul>
            <span v-else>N/A</span>
          </span>
          <span v-if="column.field === 'category'">
            <ul v-if="props.row[column.field].length">
              <li
                v-for="(category, index) in props.row.category"
                :key="`column-${index}`"
                class="has-padding-bottom-small"
              >
                {{ category.text }}
              </li>
            </ul>
            <span v-else>N/A</span>
          </span>
          <div
            v-if="column.field === 'procurementLead' && props.row[column.field]"
            class="is-flex is-aligned-center"
          >
            <app-avatar :src="getAvatarUrl(props.row[column.field])" size="medium" />
            {{ getFullName(props.row[column.field]) }}
            <span v-if="props.row[column.field].inActive" class="is-danger">{{
              $t('ui.common.deactivated')
            }}</span>
          </div>
          <div v-if="column.field === 'contractAdmin'">
            <ul v-if="props.row[column.field].length">
              <li
                v-for="(contractAdmin, index) in props.row.contractAdmin"
                :key="`column-${index}`"
              >
                <div class="is-flex is-aligned-center has-padding-bottom-small">
                  <app-avatar :src="getAvatarUrl(contractAdmin)" size="medium" />
                  {{ getFullName(contractAdmin) }}
                  <span v-if="contractAdmin.inActive" class="is-danger">{{
                    $t('ui.common.deactivated')
                  }}</span>
                </div>
              </li>
            </ul>
            <div v-else class="is-flex is-aligned-center">
              <span>N/A</span>
            </div>
          </div>
          <span v-if="column.field === 'expiresDate'">{{
            props.row[column.field] | dateString
          }}</span>
          <router-link
            v-if="column.field === 'view'"
            :to="`/projects/${props.row.projectId}/contract`"
          >
            {{ $t('ui.common.view') }}
          </router-link>
        </template>
      </el-table-column>
    </el-table>
  </app-grid>
</template>

<script>
import TableMixins from '@/mixins/table-mixins.js'
import dayjs from '@/utils/dayjs'
import cloneDeep from 'lodash/cloneDeep'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'dashboard-buyer-overview-uploads',
  mixins: [TableMixins],
  data() {
    return {
      contractClosingRanges: [0, 7, 30, 60, 90],
      viewString: 'ui.views.dashboard.buyer-dashboard-view.',
      columnDefs: [
        {
          field: 'projectNumber',
          columnHeading: this.$t('ui.views.dashboard.buyer-dashboard-view.headingProjectNumber'),
          visible: true
        },
        {
          field: 'projectName',
          columnHeading: this.$t('ui.views.dashboard.buyer-dashboard-view.headingProjectName'),
          visible: true
        },
        {
          field: 'type',
          columnHeading: this.$t('ui.views.dashboard.buyer-dashboard-view.headingType'),
          visible: true
        },
        {
          field: 'category',
          columnHeading: this.$t('ui.views.dashboard.buyer-dashboard-view.headingCategory'),
          visible: true
        },
        {
          field: 'supplier',
          columnHeading: this.$t('ui.views.dashboard.buyer-dashboard-view.headingSupplier'),
          visible: true
        },
        {
          field: 'procurementLead',
          columnHeading: this.$t('ui.views.dashboard.buyer-dashboard-view.headingProcurementLead'),
          visible: true
        },
        {
          field: 'contractAdmin',
          columnHeading: this.$t('ui.views.dashboard.buyer-dashboard-view.headingContractAdmin'),
          visible: true
        },
        {
          field: 'expiresDate',
          columnHeading: this.$t('ui.views.dashboard.buyer-dashboard-view.headingExpiresDate'),
          visible: true
        },
        {
          field: 'view',
          columnHeading: this.$t('ui.views.dashboard.buyer-dashboard-view.headingViewProject'),
          visible: true,
          width: 100
        }
      ],
      daysUntilExpired: 7,
      allProjects: []
    }
  },
  computed: {
    ...mapGetters(['getProjectsEnding']),
    dataSource() {
      return this.allProjects
    },
    contractDaysString() {
      if (this.daysUntilExpired === 0) {
        return this.$t('ui.views.dashboard.buyer-dashboard-view.statusExpired')
      }
      return this.daysUntilExpired > 1 ? this.$t('ui.common.days') : this.$t('ui.common.day')
    }
  },
  mounted() {
    const projEndingsParams = {
      limit: 1000,
      start: null,
      keywords: null,
      daysUntilExpired: 90
    }

    this.$emit('loading', true)
    this.fetchProjectsEnding(projEndingsParams)
      .then(() => {
        this.setupProjectsEnding()
        this.$emit('error', false)
      })
      .catch(() => this.$emit('loading', false))
      .finally(() => this.$emit('loading', false))
  },
  methods: {
    ...mapActions(['fetchProjectsEnding']),
    getAlign(field) {
      switch (field) {
        case 'view':
        case 'approved':
        case 'download':
          return 'center'
        default:
          return 'left'
      }
    },
    filterDataSource(proj) {
      if (this.daysUntilExpired === 0) {
        return proj.status === 'expired'
      } else {
        const { expiresDate } = proj
        const closeDiff = -1 * dayjs().diff(expiresDate, 'days')
        return proj.status !== 'expired' && closeDiff >= 0 && closeDiff < this.daysUntilExpired
      }
    },
    handleSelectContractClosingRange(val) {
      this.daysUntilExpired = val
    },
    setupProjectsEnding() {
      this.allProjects = this.mapProjects(cloneDeep(this.getProjectsEnding))
    },
    mapProjects(projects) {
      return projects.map((proj) => ({
        contacts: proj.contracts,
        projectId: proj.id,
        projectName: proj.name,
        projectNumber: proj.number,
        type: proj.contracts[0].isTerm,
        category: proj.contracts[0].contractCategories,
        supplier: proj.contracts[0].vendors,
        procurementLead: proj.projectLead,
        contractAdmin: proj.contracts[0].requisitioners,
        expiresDate: proj.contracts[0].dateClosing,
        status: proj.contracts[0].status.toLowerCase()
      }))
    },
    getAvatarUrl(user) {
      if (
        user.avatarUrl === null ||
        user.avatarUrl ===
          `https://service-nst.bidsandtenders.ca/resources/images/avatar/https://ui-avatars.com/api/?size=64&name=${user.firstName}+${user.lastName}`
      )
        return `https://ui-avatars.com/api/?size=64&name=${user.firstName}+${user.lastName}`
      let avatar = user.avatarUrl
      if (avatar.indexOf('/') === -1) {
        avatar = `${this.apiRoot}/resources/images/avatar/${avatar}`
      }
      return avatar
    },
    handleColumnReorder(row) {
      this.allProjects = this.standardTableSortWithDate(row, this.allProjects, ['expiredDate'])
    },
    getFullName(params) {
      if (params !== null) {
        return `${params.firstName} ${params.lastName}`
      }
      return 'N/A'
    }
  }
}
</script>

<style lang="scss">
.buyer-dashboard-overview__projects-ending {
  .app-grid-actions.app-grid-actions-top {
    margin-bottom: 0 !important;
  }
}
</style>
