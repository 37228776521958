import tableMixins from '@/mixins/table-mixins'
import dayjs from '@/utils/dayjs'
import { exportTableToXLS } from '@/utils/exportTable'
import { statusColorsMap, statusTypes, statusTextColorsMap } from '@/views/dashboard/dashboard-defs'
import uniqBy from 'lodash/uniqBy'
import { mapGetters } from 'vuex'

export default {
  name: 'projects-team-members',
  mixins: [tableMixins],
  watch: {
    getMappedTeamMemberProjects(val) {
      this.mappedData = val
    }
  },
  computed: {
    ...mapGetters(['getUsers', 'getTeamMemberProjects', 'getTenderTypes']),
    dataSource() {
      let projects = []

      if (this.mappedData?.length) {
        projects = this.mappedData
          .filter((val) => [val.userId, 'ALL'].includes(this.selectedTeamMember.toUpperCase()))
          .filter((value) => this.selectedStatus.indexOf(value.status) > -1)

        if (this.selectedBidClosingRange !== 'Any day') {
          projects = projects.filter(({ closingDate }) => {
            const daysLeft = this.differenceInDaysFromToday(closingDate)
            return daysLeft && daysLeft >= 0 && daysLeft < this.selectedBidClosingRange
          })
        }
      }

      return projects
    },
    tenderTypeList() {
      return this.getTenderTypes.reduce((obj, type) => ({ ...obj, [type.id]: type.text }), {})
    },
    getMappedTeamMemberProjects() {
      return (this.getTeamMemberProjects || [])
        .filter(
          (project) =>
            Object.values(statusTypes).indexOf(project.status) > -1 &&
            this.getUsers.find((user) => project.userId.toUpperCase() === user.id.toUpperCase())
        )
        .map((project) => {
          const daysLeft = this.differenceInDaysFromToday(project.closingDate)
          const userObj = this.getUsers.find(
            (user) => project.userId.toUpperCase() === user.id.toUpperCase()
          )
          return {
            ...project,
            role: project.userRoles?.filter((x) => !!x)?.join(' | '),
            user: `${userObj.firstName} ${userObj.lastName}`,
            tenderType: this.tenderTypeList[project.typeId],
            daysLeft: daysLeft ? Math.max(0, daysLeft) : 'N/A'
          }
        })
    }
  },
  data() {
    return {
      mappedData: [],
      columnDefs: [
        {
          columnHeading: 'Project Number',
          field: 'projectNumber',
          visible: true
        },
        {
          columnHeading: 'Project Name',
          field: 'projectName',
          width: 300,
          visible: true
        },
        {
          columnHeading: 'Team Member',
          field: 'user',
          visible: false
        },
        {
          columnHeading: 'Role',
          field: 'role',
          visible: false
        },
        {
          columnHeading: 'Type',
          field: 'tenderType',
          visible: true
        },
        {
          columnHeading: 'Status',
          field: 'status',
          visible: true
        },
        {
          columnHeading: 'Published Date',
          field: 'publishDate',
          visible: false
        },
        {
          columnHeading: 'Closing Date',
          field: 'closingDate',
          visible: false
        },
        {
          columnHeading: 'Registered Suppliers',
          field: 'numberOfRegisteredSuppliers',
          visible: false
        },
        {
          columnHeading: 'Addenda',
          field: 'numberOfAddenda',
          visible: false
        },
        {
          columnHeading: 'Days Left',
          field: 'daysLeft',
          visible: true
        },
        {
          columnHeading: 'Estimated Value',
          field: 'estimatedValue',
          visible: false
        }
      ],
      allStatusTypesSelected: false,
      bidClosingRanges: ['Any day', 1, 7, 14, 21, 30, 60, 90, 180],
      selectedBidClosingRange: 'Any day',
      selectedTeamMember: '',
      selectedStatus: [],
      showStatusTypes: [],
      sortedTeamMemberList: []
    }
  },
  mounted() {
    this.sortTeamMemberList()
    this.setShowStatuses()
  },
  methods: {
    sortTeamMemberList() {
      const members = uniqBy([...this.getUsers], 'id').sort((a, b) => {
        const aFN = (a.firstName || '') + (a.lastName || '')
        const bFN = (b.firstName || '') + (b.lastName || '')
        return aFN.localeCompare(bFN)
      })
      if (members.length > 0) this.selectedTeamMember = 'ALL'
      this.sortedTeamMemberList = members
    },
    setShowStatuses() {
      this.showStatusTypes = Object.values(statusTypes)
      this.selectedStatus.push(statusTypes.IN_PROGRESS)
    },
    toggleAllStatuses() {
      this.allStatusTypesSelected = !this.allStatusTypesSelected
      this.selectedStatus = []
      if (this.allStatusTypesSelected) {
        this.selectedStatus = Object.values(statusTypes)
      }
    },
    bidDaysString(range) {
      if (range === 'Any day') {
        return ''
      }
      return range > 1 ? this.$t('ui.common.days') : this.$t('ui.common.day')
    },
    handleColumnReorder(row) {
      switch (row.prop) {
        case 'estimatedValue':
          this.mappedData = this.mappedData.sort((a, b) => {
            const c1 = parseFloat(a[row.prop].replace(',', ''))
            const c2 = parseFloat(b[row.prop].replace(',', ''))
            return row.order === 'ascending' ? c1 - c2 : c2 - c1
          })
          break
        default:
          this.mappedData = this.standardTableSortWithDate(row, this.mappedData, [
            'publishDate',
            'closingDate'
          ])
      }
    },
    getStatusColor(statusString) {
      return statusColorsMap.get(statusString)
    },
    getTextColor(statusString) {
      return statusTextColorsMap.get(statusString)
    },
    differenceInDaysFromToday(closingDate) {
      if (!closingDate || closingDate === 'N/A') {
        return null
      }
      return dayjs(closingDate, 'ddd MMM DD, YYYY h:mm A').diff(dayjs(), 'days')
    },
    handleExportData(exportAll) {
      const name = exportAll ? 'All Data' : 'Filtered'
      exportTableToXLS(
        `Source Projects by Team Member - ${name} - ${dayjs().format('MM.DD.YYYY')}`,
        exportAll ? this.mappedData : this.dataSource,
        exportAll ? this.columnDefs : this.visibleColumns
      )
    }
  }
}
