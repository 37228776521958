import { api } from '@/api/api'
import ProjectService from '@/services/project/project.service'
import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import uuid from 'uuid/v1'
import {
  projectStatus,
  projectTypes,
  sourceClosingMethods,
  submissionMethod,
  submissionType,
  unsealManuallyType,
  vendorSubmissionMethods
} from '../../../views/projects/project-new-wizard-view/project-wizard-defs'
import * as types from '../../mutation-types'

const defaultState = {
  isCopy: false,
  projectToCopy: null,
  copyFormsInstructions: false,
  id: null,
  projectType: null,
  projectName: null,
  projectNumber: null,
  projectNumberAutoGenerate: null,
  projectDepartment: null,
  divisionCategory: null,
  leadAgencyName: null,
  leadAgencyNameId: null,
  numberOfAgencies: null,
  participatingAgencies: null,
  teamMembers: [],
  users: [],
  teamLead: null,
  departmentProjectLead: null,
  defaultTeamLead: null,
  hasLinkedProject: null,
  linkedProject: null,
  linkedFromProjects: null,
  cooperative: null,
  sourceMethod: null,
  sourceDetails: {
    id: null,
    awardException: null,
    closingMethod: null,
    vendorSubmission: null,
    resultsDisplay: null,
    disableRegistration: null,
    questionReminderEmailDays: 7,
    questionDeadline: null,
    questionsAfterDeadline: 0,
    irrevocablePeriod: null,
    hasDatePlannedNotice: null,
    bidMeetings: null,
    showPlanTakers: null,
    tradeAgreements: null,
    isSendToSPA: null,
    trackSubmissions: null,
    evaluateSubmissionsOnline: null,
    isHideBidMeetings: false,
    isHideBidDocuments: false,
    isHidePlanTakers: false,
    submissionAddress: null,
    showTeamMembers: null,
    showTeamMembersContact: null,
    allowMultipleSubmissions: null,
    isAttendanceTrackingOn: null,
    lvpPreferenceOption: 'Not Applicable',
    includeOtherDocument: false,
    showPurchasingRepsOnPublic: null,
    procurementType: null,
    procurementMethodLimitedType: [],
    procurementMethodLimitedTypeDescription: null,
    onlineSubmissionShowNoBid: null,
    hasPromptPayDiscount: null,
    openingProcess: null,
    datePlannedNotice: null,
    datePlannedNoticeUtc: null,
    plannedIssueDate: null,
    plannedIssueDateUtc: null,
    bidClassification: null,
    description: null
  },
  contractDetails: {
    allowVendorDocumentPublicUpload: null,
    termContract: null,
    trackOptionYears: null,
    warrantyPeriod: null,
    firmPricing: null,
    substantialCompletionDate: null,
    evaluatePerformance: null,
    workplaceSafteyCertificates: null,
    insuranceCertificates: null,
    contractDocuments: null,
    bondsSecurity: null,
    otherDocuments: null,
    otherDocumentsList: [],
    amount: null,
    contigencyAmount: null
  }
}

export const state = {
  projects: [],
  projectsEnding: [],
  myProjects: [],
  newProject: { ...defaultState },
  project: null,
  projectErrors: [],
  projectValidationErrors: [],
  projectSaveRequired: false,
  projectBackup: null,
  lastProjectSave: null,
  projectLocked: null,
  fetchingProject: false,
  projectCanSave: true,
  projectForceValidateBackDate: false,
  projectToCopy: null,
  projectToSaveAsTemplate: null,
  projectsProperties: [],
  projectDropdown: [],
  globalSaveSuccess: false,
  isClosingDateChanged: false
}

export const getters = {
  getProjects: (state) => state.projects,
  getProjectsEnding: (state) => state.projectsEnding,
  getMyProjects: (state) => state.myProjects,
  getCurrentNewProject: (state) => state.newProject,
  getProject: (state) => state.project,
  getProjectErrors: (state) => state.projectErrors,
  getProjectSaveRequired: (state) => state.projectSaveRequired,
  getProjectBackup: (state) => state.projectBackup,
  getLastProjectSave: (state) => state.lastProjectSave,
  getProjectSourceStatus: (state) => state?.project?.tenders?.[0]?.status || null,
  getProjectContractStatus: (state) => state?.project?.contracts?.[0]?.status || null,
  getProjectLocked: (state) => state.projectLocked,
  getProjectValidationErrors: (state) => state.projectValidationErrors,
  getProjectFetchStatus: (state) => state.fetchingProject,
  getProjectCanSave: (state) => state.projectCanSave,
  getProjectForceValidateBackDate: (state) => state.projectForceValidateBackDate,
  getProjectToCopy: (state) => state.projectToCopy,
  getProjectToSaveAsTemplate: (state) => state.projectToSaveAsTemplate,
  getProjectsProperties: (state) => state.projectsProperties,
  getGlobalSaveSuccess: (state) => state.globalSaveSuccess,
  getIsClosingDateChanged: (state) => state.isClosingDateChanged
}

export const actions = {
  fetchProjectsInitial: ({ dispatch }, numProjects = 20) => {
    const params = {
      filter: '',
      keywords: '',
      limit: numProjects,
      start: null
    }
    return dispatch('fetchProjects', params)
  },
  fetchProjects: async ({ commit }, { params, searchActive = false }) => {
    // Converted to POST instead of GET as we are expecting a complex object of search criterias
    // which is better to be passed in the request body than URI to avoid issues with URI character limit

    try {
      // returns {data, status, count, filter, errors}
      const { data, filter } = await ProjectService().searchProjects(params)

      commit(types.PROJECT_SET_ALL, data)

      commit(types.PROJECT_SEARCH_FILTERS_SET, searchActive === true ? filter : null)
      commit(types.PROJECT_SEARCH_FILTERS_ACTIVE_SET, searchActive)
      return data
    } catch (err) {
      commit(types.PROJECT_SET_ALL, [])
      throw err
    }
  },
  clearAllProjects: ({ commit }) => {
    commit(types.PROJECT_SET_ALL, [])
  },
  fetchProjectsEnding: ({ commit }, params) => {
    let url = `/api/v1/customer/{customerId}/node/{nodeId}/Projects/ProjectContractEnding?`

    if (params.filter && params.filter.length > 0) {
      url += `,${params.filter}`
    }

    url += `filter.limit=${params.limit}&filter.start=${params.start}&filter.searchText=${
      typeof params.keywords === 'undefined' ? '' : params.keywords
    }&filter.dateFrom=${
      params.dateFrom ? new Date(params.dateFrom).toISOString() : ''
    }&filter.dateTo=${params.dateTo ? new Date(params.dateTo).toISOString() : ''}`
    if (params.status && params.status.length > 0) {
      url += `&status=${params.status}`
    }
    url += `&daysUntilExpired=${params.daysUntilExpired}`
    return api
      .get(url)
      .then((response) => {
        if (response.data?.count > 0) {
          const result = response.data.data.projectDTO.values
          commit(types.PROJECT_ENDING_SET_ALL, result)
          return result
        }
      })
      .catch((err) => {
        commit(types.PROJECT_ENDING_SET_ALL, null)
        throw err
      })
  },
  fetchMyProjects: ({ commit }, param) => {
    // if a closes in is chosen
    const dateFrom = param.dateFrom ? new Date(param.dateFrom).setHours(0, 0, 0, 0) : null
    const dateTo = param.dateTo ? new Date(param.dateTo).setHours(23, 59, 59, 999) : null

    const url = `${
      `/api/v1/customer/{customerId}/node/{nodeId}/Projects/MyProjects?` + '&filter.start='
    }${param.start}&filter.limit=${param.limit}&filter.sortcolumn=${
      param.sortcolumn
    }&filter.sortdirection=${param.sortdirection}&filter.dateFrom=${
      dateFrom ? new Date(dateFrom).toISOString() : null
    }&filter.dateTo=${dateTo ? new Date(dateTo).toISOString() : null}`

    return api.get(url).then((response) => {
      let myProjects = []
      if (response.data.data.searchResult) myProjects = response.data.data.searchResult.values
      commit(types.MY_PROJECTS_SET_ALL, myProjects)
      return myProjects
    })
  },
  checkProjectDropdown: (_, params) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Projects/ProjectDropdown?Column=${params.Column}&Value=${params.Value}`
    return api.get(url).then((response) => response.data)
  },
  checkProject: (_, id) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Projects?projectId=${id}&filter.fields=Tenders,Phase,ProjectLead,Contracts,Department,Division,Users,LeadAgencyName`
    if (!id) return false

    return api.get(url).then((response) => response.data.data.projectDTO.values[0])
  },
  fetchProject: ({ commit }, id) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Projects?projectId=${id}&filter.fields=Tenders,Phase,ProjectLead,Contracts,Department,Division,Users,LeadAgencyName,DepartmentProjectLead`
    if (!id) return false

    // check fetch status
    if (state.fetchingProject === true) {
      return Promise.resolve([])
    }

    // update the fetch status
    commit(types.PROJECT_SET_FETCH_STATUS, true)

    // fallback cancel fetching status
    setTimeout(() => {
      if (state.fetchingProject === true) {
        commit(types.PROJECT_SET_FETCH_STATUS, false)
      }
    }, 60000)

    commit(types.PROJECT_BACKUP_SET, null)
    commit(types.PROJECT_SET_SAVE_REQUIRED, { projectSaveRequired: false })
    commit(types.PROJECT_SET_FORCE_VALIDATE_BACKDATE, { projectForceValidateBackDate: false })

    return api
      .get(url)
      .then((response) => {
        const project = response.data.data.projectDTO.values[0]

        // update the fetch status
        commit(types.PROJECT_SET_FETCH_STATUS, false)

        // set project backup, this will be used for comparison when changing critical properties on what is before and after values
        commit(types.PROJECT_BACKUP_SET, project)

        if (project) {
          commit(types.PROJECT_SET, project)
        }
        return Promise.resolve(project || null)
      })
      .catch((err) => {
        // update the fetch status
        commit(types.PROJECT_SET_FETCH_STATUS, false)
        commit(types.PROJECT_SET, null)
        throw err
      })
  },
  resetProject: ({ commit }) => {
    commit(types.PROJECT_SET, null)
  },
  fetchProjectWebDemo: (_, id) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Projects/WebDemo?projectId=${id}`
    if (!id) return false

    return api.get(url).then((response) => {
      const url = response.data.data.searchResult.values[0].webDemoURL
      window.open(url)
    })
  },
  fetchProjectShare: (_, { id, context }) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Projects/Share?projectId=${id}`
    if (!id) return false

    return api.get(url).then((response) => {
      const url = response.data.data.searchResult.values[0].shareURL
      if (url && context) {
        const match = url.match(/https:\/\/[^\s]+/)
        if (match) {
          const link = match[0]
          navigator.clipboard.writeText(link).then(
            () => {
              context.$notify({
                title: context.$t('ui.common.success'),
                message: context.$t('ui.common.clipboardCopySuccess'),
                type: 'success'
              })
            },
            () => {
              context.$notify({
                title: context.$t('ui.components.notifications.titles.error'),
                message: context.$t('ui.common.clipboardCopyError'),
                type: 'error'
              })
            }
          )
        } else {
          console.error('Link not found in the expected format')
        }
      } else {
        window.location = url
      }
    })
  },
  fetchProjectDetailPreview: (_, id) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Projects/Share?projectId=${id}`
    if (!id) return false
    return api.get(url).then((response) => {
      const url = response.data.data.searchResult.values[0].shareURL
      const match = url.match(/https:\/\/[^\s]+/)
      if (match) {
        const link = match[0]
        window.open(link)
      } else {
        console.error('Link not found in the expected format')
      }
    })
  },
  fetchProjectHTML: (_, id) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Projects/HTML?projectId=${id}`
    if (!id) return false

    return api.get(url).then((response) => {
      const url = response.data.data.searchResult.values[0].htmlURL
      window.open(url)
    })
  },
  createNewProjectFromCopy: (_, payload) => {
    const model = payload.project
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Projects/Copy?copyFormsInstructions=${model.copyFormsInstructions}`
    const jsonModel = {
      id: model.projectToCopy,
      name: model.projectName,
      Number: model.projectNumber,
      NumberAutoGenerate: model.projectNumberAutoGenerate,
      hasLinkedProject: model.hasLinkedProject,
      linkedProject: {
        id: model.linkedProject
      },
      linkedFromProjects: model.linkedFromProjects,
      hasCOOP: model.cooperative,
      projectLead: {
        id: model.teamLead
      },
      department: {
        id: model.projectDepartment
      },
      division: {
        id: model.divisionCategory
      },
      leadAgency: model.leadAgency,
      leadAgencyName: {
        id: model.leadAgencyNameId
      },
      departmentProjectLead: {
        id: model.departmentProjectLead
      },
      numberOfAgencies: model.numberOfAgencies,
      participatingAgencies: model.participatingAgencies
    }
    if (payload.intakeData && payload.intakeData.intakeRequestId !== null) {
      jsonModel.intakeRequestId = payload.intakeData.intakeRequestId
      jsonModel.tenders = [
        {
          initialPurchasingStartDate: payload.intakeData?.sourceDetails?.initialPurchasingStartDate,
          hasSiteMeetings: payload.intakeData?.sourceDetails?.bidMeetings
        }
      ]
    }
    return api.post(url, jsonModel).then((response) => response.data.data.projectDTO.values[0])
  },
  createNewProject: ({ rootState }, payload) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Projects`
    const model = payload.project
    const intakeData = payload.intakeData || null

    const jsonModel = {
      id: model.id,
      name: model.projectName,
      Number: model.projectNumber,
      NumberAutoGenerate: model.projectNumberAutoGenerate,
      hasCOOP: model.cooperative,
      hasLinkedProject: model.hasLinkedProject,
      linkedProject: {
        id: model.linkedProject
      },
      linkedFromProjects: model.linkedFromProjects,
      projectLead: {
        id: model.teamLead
      },
      departmentProjectLead: {
        id: model.departmentProjectLead
      },
      department: {
        id: model.projectDepartment
      },
      division: {
        id: model.divisionCategory
      },
      node: {
        id: (rootState.authCredential.authTenderNode || {}).id || '' // localStorage.getItem('nodeId')
      },
      tenders: [],
      contracts: [],
      phase: {
        id: '6F0EEB95-37FD-495A-8C75-79F2DE9DE19B'
      },
      leadAgency: model.leadAgency,
      leadAgencyName: {
        id: model.leadAgencyNameId
      },
      numberOfAgencies: model.numberOfAgencies,
      participatingAgencies: model.participatingAgencies
    }

    if (intakeData) {
      model.users = intakeData.users
      if (intakeData.intakeRequestId !== null) {
        jsonModel.intakeRequestId = intakeData.intakeRequestId
      }
    }

    if (
      model.projectType === projectTypes.SOURCE ||
      model.projectType === projectTypes.SOURCE_CONTRACT ||
      model.projectType === projectTypes.TEMPLATE
    ) {
      jsonModel.tenders[0] = {
        id: model.sourceDetails.id,
        name: model.projectName,
        number: uuid(),
        status:
          model.projectType === projectTypes.TEMPLATE
            ? projectStatus.TEMPLATE
            : projectStatus.DESIGN_MODE_PENDING,
        isCloseRegistrationAfterMeetingDate: model.sourceDetails.disableRegistration,
        hasQuestionDeadline: model.sourceDetails.questionDeadline,
        questionReminderEmailDays: model.sourceDetails.questionReminderEmailDays,
        hasDatePlannedNotice: model.sourceDetails.hasDatePlannedNotice,
        isHideBidMeetings: model.sourceDetails.isHideBidMeetings,
        bidClassification: model.sourceDetails.bidClassification,
        datePlannedNotice: model.sourceDetails.datePlannedNotice,
        datePlannedNoticeUtc: model.sourceDetails.datePlannedNoticeUtc,
        plannedIssueDate: model.sourceDetails.plannedIssueDate,
        plannedIssueDateUtc: model.sourceDetails.plannedIssueDateUtc,
        isHideBidDocuments: model.sourceDetails.isHideBidDocuments,
        isHidePlanTakers: model.sourceDetails.isHidePlanTakers,
        isCloseQuestionsAfterDeadline: model.sourceDetails.questionsAfterDeadline,
        hasIrrevocablePeriod: model.sourceDetails.irrevocablePeriod,
        hasSiteMeetings: model.sourceDetails.bidMeetings,
        isAttendanceTrackingOn: model.sourceDetails.isAttendanceTrackingOn,
        hasTradeAgreements: model.sourceDetails.tradeAgreements,
        isSendToSPA: model.sourceDetails.isSendToSPA,
        hasCompliancy: model.sourceDetails.trackSubmissions,
        evaluations: model.sourceDetails.evaluateSubmissionsOnline,
        scope: model.sourceMethod,
        showPlanTakers: model.sourceDetails.showPlanTakers,
        submission: model.submission,
        allowMultipleSubmissions: model.sourceDetails.allowMultipleSubmissions,
        submissionAddress: model.sourceDetails.submissionAddress,
        unsealManually: null,
        showSubmitted: null,
        showPurchasingRepsOnPublic: model.sourceDetails.showPurchasingRepsOnPublic,
        showTeamMembers: model.sourceDetails.showTeamMembers,
        showTeamMembersContact: model.sourceDetails.showTeamMembersContact,
        allowFullBidDetailsUrl: true,
        allowBidDocumentPreview: true,
        publicOpening: false,
        leadAgency: model.leadAgency,
        leadAgencyName: {
          id: model.leadAgencyNameId
        },
        includeOtherDocument: model.sourceDetails.includeOtherDocument,
        lvpPreferenceOption: model.sourceDetails.lvpPreferenceOption,
        numberOfAgencies: model.numberOfAgencies,
        participatingAgencies: model.participatingAgencies,
        node: {
          id: (rootState.authCredential.authTenderNode || {}).id || ''
        },
        type: {
          id: model.sourceDetails.type
        },
        procurementMethodLimitedType: {
          id: model.sourceDetails.procurementMethodLimitedType
        },
        procurementMethodLimitedTypeDescription:
          model.sourceDetails.procurementMethodLimitedTypeDescription,
        onlineSubmissionShowNoBid: model.sourceDetails.onlineSubmissionShowNoBid,
        hasPromptPayDiscount: model.sourceDetails.hasPromptPayDiscount,
        openingProcess: model.sourceDetails.openingProcess,
        description: model.sourceDetails.description
      }

      if (intakeData) {
        const fields = [
          'procurementType',
          'type',
          'bidClassification',
          'bidMeetings',
          'description',
          'categories',
          'dateEstimatedAward',
          'dateAvailable',
          'dateAvailableUtc',
          'estimatedAnnualValue',
          'estimatedValue',
          'initialPurchasingStartDate'
          // 'meetingLocations',
          // 'vendorInvitationListNew',
        ]

        fields.map((field) => {
          if (!jsonModel.tenders[0][field]) {
            jsonModel.tenders[0][field] = intakeData.sourceDetails[field]
          }
        })
      }
    }

    if (
      model.projectType === projectTypes.CONTRACT ||
      model.projectType === projectTypes.SOURCE_CONTRACT
    ) {
      jsonModel.contracts[0] = {
        name: model.projectName,
        number: model.projectNumber,
        status: projectStatus.DESIGN_MODE_PENDING,
        hasOptionYears: model.contractDetails.trackOptionYears,
        hasWarrantyPeriod: model.contractDetails.warrantyPeriod,
        hasContractPerformance: model.contractDetails.evaluatePerformance,
        hasWSIB: model.contractDetails.workplaceSafetyCertificates,
        hasInsurance: model.contractDetails.insuranceCertificates,
        hasContractDocuments: model.contractDetails.contractDocuments,
        hasVendorDocuments: model.contractDetails.vendorDocuments,
        hasBondsAndSecurity: model.contractDetails.bondsSecurity,
        isTerm: model.contractDetails.termContract,
        substantialCompletionDate: model.contractDetails.substantialCompletionDate,
        hasFirmPricingPeriod: model.contractDetails.firmPricing,
        allowVendorDocumentPublicUpload: model.contractDetails.allowVendorDocumentPublicUpload,
        amount: model.contractDetails.amount,
        contigencyAmount: model.contractDetails.contigencyAmount
      }
    }

    // resultsDisplay Value
    // 1 - Do not display anything
    // 2 - Display only the names of vendors who submitted
    // 3 - Display the names and results of the vendors who submitted
    // 4 - Let me choose which results to display
    //
    // unsealManuallyType
    // AUTOMATICALLY_DISPLAY: 2,
    // LET_ME_CHOOSE: 0,
    // OTHERS: 1
    //
    if (jsonModel.tenders[0]) {
      // extra if above added because 'cant adjust showSubmitted of undefined'
      if (
        model.sourceDetails.closingMethod === sourceClosingMethods.SEALED &&
        model.sourceDetails.resultsDisplay === submissionType.SUBMISSION_DO_NOT_DISPLAY
      ) {
        jsonModel.tenders[0].showSubmitted = false
        jsonModel.tenders[0].unsealManually = unsealManuallyType.OTHERS
      } else if (
        model.sourceDetails.closingMethod === sourceClosingMethods.SEALED &&
        model.sourceDetails.resultsDisplay === submissionType.SUBMISSION_DISPLAY_NAMES
      ) {
        jsonModel.tenders[0].showSubmitted = true
        jsonModel.tenders[0].unsealManually = unsealManuallyType.OTHERS
      } else if (
        model.sourceDetails.closingMethod === sourceClosingMethods.UNSEALED &&
        model.sourceDetails.resultsDisplay === submissionType.SUBMISSION_DO_NOT_DISPLAY
      ) {
        jsonModel.tenders[0].showSubmitted = false
        jsonModel.tenders[0].unsealManually = unsealManuallyType.LET_ME_CHOOSE
      } else if (
        model.sourceDetails.closingMethod === sourceClosingMethods.UNSEALED &&
        model.sourceDetails.resultsDisplay === submissionType.SUBMISSION_DISPLAY_NAMES
      ) {
        jsonModel.tenders[0].showSubmitted = true
        jsonModel.tenders[0].unsealManually = unsealManuallyType.LET_ME_CHOOSE
      } else if (
        model.sourceDetails.closingMethod === sourceClosingMethods.UNSEALED &&
        model.sourceDetails.resultsDisplay === submissionType.SUBMISSION_DISPLAY_RESULTS
      ) {
        jsonModel.tenders[0].showSubmitted = true
        jsonModel.tenders[0].unsealManually = unsealManuallyType.AUTOMATICALLY_DISPLAY
      } else {
        jsonModel.tenders[0].showSubmitted = false
        jsonModel.tenders[0].unsealManually = unsealManuallyType.LET_ME_CHOOSE
      }
    }

    // vendorSubmissionMethods
    // 1 - Online
    // 0 - Hardcopy
    if (jsonModel.tenders[0]) {
      // same as above
      if (model.sourceDetails.vendorSubmission === vendorSubmissionMethods.ONLINE) {
        jsonModel.tenders[0].submission = submissionMethod.ONLINE
      } else {
        jsonModel.tenders[0].submission = submissionMethod.HARD_COPY
      }
    }

    return api.post(url, jsonModel).then((response) => response.data.data.projectDTO.values[0])
  },
  updateNewProject: ({ commit, rootState }, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Projects`

    model.Node = { id: (rootState.authCredential.authTenderNode || {}).id || '' } // localStorage.getItem('nodeId')

    if (model.tenders[0]) {
      const tender = Object.assign({}, model.tenders[0])
      tender.Node = { id: (rootState.authCredential.authTenderNode || {}).id || '' } // localStorage.getItem('nodeId')
      model.tenders[0] = tender
    }
    return api.put(url, model).then((response) => {
      const project = response.data.data.projectDTO.values[0]
      commit(types.PROJECT_SET, project)
      return project
    })
  },
  createCurrentNewProject: ({ commit }, project) => {
    commit(types.PROJECT_SET_NEW, project)
  },
  updateCurrentNewProject: ({ commit }, model) => {
    commit(types.PROJECT_UPDATE_NEW, model)
    return Promise.resolve('')
  },
  resetCurrentNewProject: ({ commit }) => {
    commit(types.PROJECT_RESET_NEW)
  },
  updateProject: ({ commit }, project) => {
    const newProject = Object.assign({}, state.project, project)
    commit(types.PROJECT_SET, newProject)
  },
  async saveProjectWithModel({ rootState }, model) {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Projects`
    model.Node = { id: (rootState.authCredential.authTenderNode || {}).id || '' } // localStorage.getItem('nodeId')
    if (model.tenders[0]) {
      const tender = Object.assign({}, model.tenders[0])
      tender.Node = { id: (rootState.authCredential.authTenderNode || {}).id || '' } // localStorage.getItem('nodeId')
      model.tenders[0] = tender
    }
    try {
      const response = await api.put(url, model)
      const project = response.data.data.projectDTO.values[0]

      return project
    } catch (error) {
      throw error
    }
  },
  saveProject: ({ dispatch, rootState, commit }, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Projects`

    model.Node = { id: (rootState.authCredential.authTenderNode || {}).id || '' } // localStorage.getItem('nodeId')

    if (model.tenders[0]) {
      const tender = Object.assign({}, model.tenders[0])
      tender.Node = { id: (rootState.authCredential.authTenderNode || {}).id || '' } // localStorage.getItem('nodeId')
      model.tenders[0] = tender
    }
    commit(types.GLOBAL_PROJECT_SAVE_SUCCESS, false)
    return api
      .put(url, model)
      .then((response) => {
        const project = response.data.data.projectDTO.values[0]
        dispatch('setProjectCanSave', false)
        dispatch('setLastProjectSave', Date.now())
        dispatch('fetchProject', project.id).then(() => {
          dispatch('setProjectCanSave', true)
          dispatch('setProjectSaveRequired', false)
          commit(types.GLOBAL_PROJECT_SAVE_SUCCESS, true)
          return project
        })
      })
      .catch((err) => {
        dispatch('setProjectCanSave', true)
        throw err
      })
  },
  setLastProjectSave: ({ commit }, time) => {
    commit(types.PROJECT_SET_LAST_SAVE, time)
  },
  setProjectCanSave: ({ commit }, canSave) => {
    commit(types.PROJECT_CAN_SAVE_SET, canSave)
  },
  setProjectError: ({ commit }, error) => {
    commit(types.PROJECT_ERROR_SET, error)
  },
  unsetProjectError: ({ commit }, error) => {
    commit(types.PROJECT_ERROR_UNSET, error)
  },
  clearProjectErrors: ({ commit }) => {
    commit(types.PROJECT_ERROR_UNSET_ALL)
  },
  setProjectSaveRequired: ({ commit }, value) => {
    commit(types.PROJECT_SET_SAVE_REQUIRED, { projectSaveRequired: value })
  },
  setProjectBackup: ({ commit }, project) => {
    commit(types.PROJECT_BACKUP_SET, project)
  },
  setProjectLocked: ({ commit }, locked) => {
    commit(types.PROJECT_SET_LOCKED, locked)
  },
  setProjectValidationErrors: ({ commit }, errors) => {
    commit(types.PROJECT_VALIDATION_ERROR_SET, errors)
  },
  setProjectStatus: ({ dispatch }, param) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Projects/status?projectId=${param.id}&status=${param.status}`

    return api.put(url, state.project).then((response) => {
      dispatch('setLastProjectSave', Date.now())
      const project = response.data.data.projectDTO.values[0]
      dispatch('fetchProject', project.id).then(() => {
        dispatch('setProjectSaveRequired', false)
        return project
      })
    })
  },
  deleteProject: (_, projectId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Projects?projectId=${projectId}`

    return api.delete(url)
  },
  fetchProjectsProperties: ({ commit }, forceUpdate = false) => {
    // when signalr event received to fetch new properties
    if (forceUpdate) {
      commit(types.PROJECTS_SET_PROJECTSPROPERTIES, [])
    }

    // Return cached value if exists
    if (state.projectsProperties.length) {
      return Promise.resolve(state.projectsProperties)
    }

    const url = '/api/v1/customer/{customerId}/node/{nodeId}/Projects/ProjectsProperties'

    return api.get(url).then((response) => {
      const result = response?.data?.data?.searchResult?.values || []
      commit(types.PROJECTS_SET_PROJECTSPROPERTIES, result)
      return result
    })
  },
  updateProjectSubmissions: ({ commit }, submissions) => {
    commit(types.PROJECT_UPDATE_SUBMISSIONS, submissions)
    return Promise.resolve('')
  },
  updateProjectAddendums: ({ commit }, addendums) => {
    commit(types.PROJECT_UPDATE_ADDENDUMS, addendums)
    return Promise.resolve('')
  },
  updateProjectDocument: ({ commit }, document) => {
    commit(types.PROJECT_UPDATE_DOCUMENT, document)
    return Promise.resolve('')
  },
  updateProjectPlanTakers: ({ commit }, planTakers) => {
    commit(types.PROJECT_UPDATE_PLANTAKERS, planTakers)
    return Promise.resolve('')
  },
  updateProjectSuppliers: ({ commit }, vendor) => {
    commit(types.PROJECT_UPDATE_SUPPLIERS, vendor)
    return Promise.resolve('')
  },
  updateProjectVendorQuestions: ({ commit }, vendorQuestions) => {
    commit(types.PROJECT_UPDATE_VENDOR_QUESTIONS, vendorQuestions)
    return Promise.resolve('')
  },
  setProjectForceValidateBackDate: ({ commit }, value) => {
    commit(types.PROJECT_SET_FORCE_VALIDATE_BACKDATE, { projectForceValidateBackDate: value })
  },
  setProjectToCopy: ({ commit }, projectToCopy) => {
    commit(types.PROJECT_TO_COPY_SET, projectToCopy)
    return Promise.resolve('')
  },
  setProjectToSaveAsTemplate: ({ commit }, projectToSaveAsTemplate) => {
    commit(types.PROJECT_TO_SAVE_AS_TEMPLATE_SET, projectToSaveAsTemplate)
    return Promise.resolve('')
  },
  updateProjectAwardPhaseStatus: ({ commit }, status) => {
    commit(types.PROJECT_UPDATE_AWARD_PHASE_STATUS, status)
    return Promise.resolve('')
  },
  updateIsClosingDateChanged: ({ commit }, isChanged) => {
    commit(types.PROJECT_UPDATE_CLOSING_DATE_CHANGED, isChanged)
  }
}

export const mutations = {
  [types.PROJECT_SET_ALL](state, projects) {
    state.projects = projects
  },
  [types.PROJECT_ENDING_SET_ALL](state, projectsEnding) {
    state.projectsEnding = projectsEnding
  },
  [types.MY_PROJECTS_SET_ALL](state, myProjects) {
    state.myProjects = myProjects
  },
  [types.PROJECT_SET_NEW](state, newProject) {
    state.newProject = {
      ...state.newProject,
      ...newProject
    }
  },
  [types.PROJECT_UPDATE_NEW](state, model) {
    const newProject = cloneDeep(state.newProject)

    state.newProject = merge(newProject, model)
  },
  [types.PROJECT_RESET_NEW](state) {
    state.newProject = { ...defaultState }
  },
  [types.PROJECT_SET](state, project) {
    // look for any publish or closing dates in the source with a year of 9999 and null them out
    if (project && project.tenders && project.tenders.length > 0) {
      if (
        project.tenders[0].dateAvailableUtc &&
        new Date(project.tenders[0].dateAvailableUtc) > new Date(9999, 0)
      ) {
        project.tenders[0].dateAvailableUtc = null
      }
      if (
        project.tenders[0].dateAvailable &&
        new Date(project.tenders[0].dateAvailable) > new Date(9999, 0)
      ) {
        project.tenders[0].dateAvailable = null
      }
      if (
        project.tenders[0].dateClosingUtc &&
        new Date(project.tenders[0].dateClosingUtc) > new Date(9999, 0)
      ) {
        project.tenders[0].dateClosingUtc = null
      }
      if (
        project.tenders[0].dateClosing &&
        new Date(project.tenders[0].dateClosing) > new Date(9999, 0)
      ) {
        project.tenders[0].dateClosing = null
      }
    }
    state.project = project
  },
  [types.PROJECT_ERROR_SET](state, error) {
    if (state.projectErrors.indexOf(error) === -1) {
      state.projectErrors.push(error)
    }
  },
  [types.PROJECT_ERROR_UNSET](state, error) {
    const index = state.projectErrors.indexOf(error)
    if (index !== -1) {
      state.projectErrors.splice(index, 1)
    }
  },
  [types.PROJECT_ERROR_UNSET_ALL](state) {
    state.projectErrors = []
  },
  [types.PROJECT_SET_SAVE_REQUIRED](state, { projectSaveRequired }) {
    state.projectSaveRequired = projectSaveRequired
  },
  [types.PROJECT_BACKUP_SET](state, project) {
    state.projectBackup = project
  },
  [types.PROJECT_SET_LAST_SAVE](state, date) {
    state.lastProjectSave = date
  },
  [types.PROJECT_SET_LOCKED](state, locked) {
    state.projectLocked = locked
  },
  [types.PROJECT_VALIDATION_ERROR_SET](state, errors) {
    state.projectValidationErrors = errors
  },
  [types.PROJECT_UPDATE_SUBMISSIONS](state, submissions) {
    if (submissions && submissions.length > 0) {
      submissions.forEach((updatedSub) => {
        const submittedPlantakers = state.project.tenders[0].planTakers.filter(
          (pt) => (pt.id = updatedSub.planTaker.id)
        )

        submittedPlantakers.forEach((matchedPlantaker) => {
          matchedPlantaker.submissionStatus = updatedSub.planTaker.submissionStatus
        })

        // look for any plantaker submissions with the affected submission
        const matchedPlantakers = state.project.tenders[0].planTakers.filter(
          (pt) =>
            (pt.planTakerSubmissions &&
              pt.planTakerSubmissions.some(
                (pts) => pts.submission && pts.submission.id === updatedSub.id
              )) ||
            (pt.sumissions &&
              pt.sumissions &&
              pt.sumissions.some((pts) => pts.id === updatedSub.id))
        )

        matchedPlantakers.forEach((matchedPlantaker) => {
          const matchedPtSubIndex = matchedPlantaker.planTakerSubmissions.findIndex(
            (pts) => pts.submission && pts.submission.id === updatedSub.id
          )

          if (matchedPtSubIndex === 0) {
            // there should only ever be one
            matchedPlantaker.planTakerSubmissions = null
          } else {
            const err = 'Mismatched plantaker submission id on submission update'
            throw err
          }

          const matchedSubIndex = matchedPlantaker.submissions.findIndex(
            (sub) => sub.id === updatedSub.id
          )

          if (matchedSubIndex !== -1) {
            matchedPlantaker.submissions.splice(matchedSubIndex, 1)
            matchedPlantaker.submissions.push(updatedSub)
          } else {
            const err = 'Mismatched submission id on submission update'
            throw err
          }
        })
      })
    }
  },
  [types.PROJECT_UPDATE_ADDENDUMS](state, addendums) {
    state.project.tenders[0].addendums = addendums
  },
  [types.PROJECT_UPDATE_DOCUMENT](state, document) {
    state.project.tenders[0].document = document
  },
  [types.PROJECT_UPDATE_PLANTAKERS](state, planTakers) {
    state.project.tenders[0].planTakers = planTakers
    state.project.tenders[0].awardedPlanTakers = []

    for (let i = 0; i < planTakers.length; i++) {
      if (
        planTakers[i].planTakerSubmissions != null &&
        planTakers[i].planTakerSubmissions.filter((r) => r.awarded).length > 0
      ) {
        state.project.tenders[0].awardedPlanTakers.push(
          planTakers[i].planTakerSubmissions.filter((r) => r.awarded)[0]
        )
      }
    }
  },
  [types.PROJECT_UPDATE_SUPPLIERS](state, vendor) {
    if (!state.project.contracts[0].vendors) {
      state.project.contracts[0].vendors = []
    }

    const matchedIndex = state.project.contracts[0].vendors.findIndex((v) => v.id === vendor.id)
    if (matchedIndex !== -1) {
      // pop it off the array and push a new one so that it triggers a recalc on the ui
      state.project.contracts[0].vendors.splice(matchedIndex, 1)
      state.project.contracts[0].vendors.push(vendor)
    } else {
      state.project.contracts[0].vendors.push(vendor)
    }
  },
  [types.PROJECT_UPDATE_VENDOR_QUESTIONS](state, vendorQuestions) {
    state.project.tenders[0].vendorQuestions = cloneDeep(vendorQuestions)
  },
  [types.PROJECT_SET_FETCH_STATUS](state, status) {
    state.fetchingProject = status
  },
  [types.PROJECT_CAN_SAVE_SET](state, canSave) {
    state.projectCanSave = canSave
  },
  [types.PROJECT_SET_FORCE_VALIDATE_BACKDATE](state, { projectForceValidateBackDate }) {
    state.projectForceValidateBackDate = projectForceValidateBackDate
  },
  [types.PROJECT_TO_COPY_SET](state, projectToCopy) {
    state.projectToCopy = projectToCopy
  },
  [types.PROJECT_TO_SAVE_AS_TEMPLATE_SET](state, projectToSaveAsTemplate) {
    state.projectToSaveAsTemplate = projectToSaveAsTemplate
  },
  [types.PROJECTS_SET_PROJECTSPROPERTIES](state, projectsProperties) {
    state.projectsProperties = projectsProperties
  },
  [types.PROJECT_UPDATE_AWARD_PHASE_STATUS](state, status) {
    state.project.awards[0].status = status
  },
  [types.GLOBAL_PROJECT_SAVE_SUCCESS](state, success) {
    state.globalSaveSuccess = success
  },
  [types.PROJECT_UPDATE_CLOSING_DATE_CHANGED](state, isChanged) {
    state.isClosingDateChanged = isChanged
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
