<script setup>
import AppHamburgerButton from '@/components/app-button/app-hamburger-button.vue'
import useClickOutside from '@/composables/clickOutside'
import { useStore } from '@/store'
import { isDevQA } from '@/utils/environments'
import { useFeatureHelpers } from '@/utils/featureHelpers'
import throttle from 'lodash/throttle'
import { nextTick, onMounted, onUnmounted, ref } from 'vue'
import { useRouter } from 'vue-router/composables'
// eslint-disable-next-line no-unused-vars
import Teleport from 'vue2-teleport'
import AppCloseButton from '../app-button/app-close-button.vue'
import navItems from './app-settings-nav-items.js'

const { isFeatureEnabled } = useFeatureHelpers()
const router = useRouter()

const store = useStore()
const menuId = ref('settings-menu')
const settingsMenuOpen = ref(false)
const settingsMenuEl = ref(null)
const settingsMenuControlEl = ref(null)
const navEl = ref(null)
const listEl = ref(null)
const linkList = ref([])

const setLinkList = throttle(async function () {
  await nextTick()
  linkList.value = [...document.querySelectorAll(`#${menuId.value} a:not([disabled])`)]
  // look for .app-settings-nav__close-button in linkList and move it to the end
  const closeButton = linkList.value.find((item) =>
    item.classList.contains('app-settings-nav__close-button')
  )
  if (closeButton) {
    linkList.value = linkList.value.filter(
      (item) => !item.classList.contains('app-settings-nav__close-button')
    )
    linkList.value.push(closeButton)
  }

  // address mounting timing. Just in case we were slow populating the list, check and try again.
  if (linkList.value.length < navItems.length + 1) {
    setLinkList()
  }
}, 500)

const openSettingsMenu = throttle(async function () {
  settingsMenuOpen.value = true
  await nextTick()
  document.activeElement.blur()
}, 100)

function handleArrowDown() {
  if (!settingsMenuOpen.value) return
  // focus on next item in linkList.value or, if at the end of linkList items, focus the first item
  const activeElement = document.activeElement
  const activeElementIndex = linkList.value.indexOf(activeElement)

  if (activeElementIndex === linkList.value.length - 1 || activeElementIndex === -1) {
    linkList.value[0].focus()
  } else {
    linkList.value[activeElementIndex + 1].focus()
  }
}

function handleArrowUp() {
  if (!settingsMenuOpen.value) return
  // focus on previous item in linkList.value or, if at the beginning of linkList items, focus the last item
  const activeElement = document.activeElement
  const activeElementIndex = linkList.value.indexOf(activeElement)

  if (activeElementIndex === 0 || activeElementIndex === -1) {
    linkList.value[linkList.value.length - 1].focus()
  } else {
    linkList.value[activeElementIndex - 1].focus()
  }
}

async function handleCloseSettingsMenu(focusOnControl = false) {
  settingsMenuOpen.value = false
  await nextTick()
  if (focusOnControl) {
    await new Promise((resolve) => setTimeout(resolve, 200))
    settingsMenuControlEl.value?.$el?.focus()
  }
}

async function handleKeyPress(event) {
  if (!linkList.value.length) {
    setLinkList()
    await nextTick()
  }

  if (event.keyCode === 27) {
    if (!settingsMenuOpen.value || event.target.disabled) return

    handleCloseSettingsMenu(true)
  } else if (event.keyCode === 40) {
    handleArrowDown()
  } else if (event.keyCode === 38) {
    handleArrowUp()
  }

  // if we pressed enter on a link in the menu, close the menu
  if (event.keyCode === 13 && linkList.value.includes(event.target)) {
    // we'll check to see if it was the close button
    const lastItem = linkList.value[linkList.value.length - 1]

    await nextTick()
    handleCloseSettingsMenu(lastItem === event.target)
  }
}

router.beforeEach((to, from, next) => {
  handleCloseSettingsMenu()
  next()
})

function getNavItems() {
  const navs = navItems.filter(
    (element) =>
      !element.esolutionsOnly ||
      store.getters.getCustomer?.id.toLowerCase() ===
        import.meta.env.VITE_ESOL_CUST_ID?.toLowerCase()
  )

  if (isDevQA() && isFeatureEnabled('DEBUG_VIEW')) {
    navs.push({
      path: '/debug-view',
      label: 'Debug View (Dev Only)',
      esolutionsOnly: true
    })
  }

  return navs
}

function handleEvaluatorPortal() {
  const nodeId = (store.getters.getAuthTenderNode || {}).id || '' // localStorage.getItem('nodeId')
  const url = `https://service-nst.bidsandtenders.ca` + `/api/v1/login/evaluator/node/${nodeId}`
  window.location = url
}

function bindKeyEvent() {
  document.addEventListener('keydown', handleKeyPress, true)
}

const handleClickOutsideNavMenu = throttle(async function () {
  if (!settingsMenuOpen.value) return
  handleCloseSettingsMenu()
  await nextTick()
}, 100)

useClickOutside(settingsMenuEl, handleClickOutsideNavMenu)

onMounted(async () => {
  await nextTick()
  bindKeyEvent()
  handleCloseSettingsMenu()
  setLinkList()
})

onUnmounted(() => {
  document.removeEventListener('keydown', handleKeyPress)
  handleCloseSettingsMenu()
})
</script>

<template>
  <nav ref="navEl" role="navigation" aria-label="settings menu" class="app-settings-nav">
    <AppHamburgerButton
      id="settings-menu-control"
      ref="settingsMenuControlEl"
      :controls="menuId"
      :expanded="settingsMenuOpen"
      aria-label="open settings menu"
      @toggle="openSettingsMenu"
    />
    <Teleport to="body">
      <aside
        :id="menuId"
        ref="settingsMenuEl"
        class="app-settings-nav__menu"
        :class="{ 'is-open': settingsMenuOpen }"
        role="navigation"
        :aria-hidden="!settingsMenuOpen"
        @keypress="handleKeyPress"
      >
        <app-focus-trap
          :disabled="!settingsMenuOpen"
          class="trap-focus is-flex is-flex-column is-justified-between has-full-height"
        >
          <AppCloseButton
            class="app-settings-nav__close-button"
            reverse
            @click="handleCloseSettingsMenu(true)"
          />
          <ul ref="listEl" class="has-full-height has-margin-bottom-auto">
            <li role="menu-item">
              <component :is="'a'" tabindex="0" @click="handleEvaluatorPortal">
                {{ $t('ui.components.navBar.general.evaluatorPortal') }}
              </component>
            </li>
            <li v-for="(item, i) in getNavItems()" :key="`nav-item-${i}`" role="menu-item">
              <router-link
                v-if="!item.tag"
                :to="item.path"
                :target="item.target"
                :disabled="item.disabled"
                :tabindex="settingsMenuOpen ? 0 : -1"
              >
                {{ $t(item.label) }}
              </router-link>
              <component
                :is="item.tag"
                v-else
                :href="item.path"
                :target="item.target"
                :disabled="item.disabled"
                :tabindex="settingsMenuOpen ? 0 : -1"
              >
                {{ $t(item.label) }}
              </component>
            </li>
          </ul>
          <div class="app-settings-nav__contact has-margin-top-large">
            <app-title size="4" heading="4">
              {{ $t('ui.components.app-mainnav.contactSupport') }}
            </app-title>
            <p class="has-margin-bottom-tiny">
              P: <a href="tel:18005944798" :tabindex="settingsMenuOpen ? 0 : -1">1-800-594-4798</a>
            </p>
            <p class="has-margin-bottom-tiny">
              E:
              <a href="mailto:support@bidsandtenders.ca" :tabindex="settingsMenuOpen ? 0 : -1"
                >support@bidsandtenders.ca</a
              >
            </p>
            <a
              href="https://bidsandtenders.zendesk.com/hc/en-us/requests/new"
              :tabindex="settingsMenuOpen ? 0 : -1"
              target="_blank"
            >
              {{ $t('ui.components.app-mainnav.submitTicket') }}
            </a>
          </div>
        </app-focus-trap>
      </aside>
    </Teleport>
  </nav>
</template>

<style lang="scss" scoped>
$hamburgerHeight: $appbar-height;
aside {
  background-color: $c-primary-shade;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  right: 0;
  padding: $appbar-height $gap-large $gap-large;
  position: fixed;
  top: 0;
  z-index: 100;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;

  @include breakpoint('md') {
    width: $sidebar-width-base;
  }

  &.is-open {
    transform: translateX(0);
  }

  a {
    color: white;
  }
}

.app-settings-nav__close-button {
  position: absolute;
  top: $gap;
  right: $gap;
}

#settings-menu-control {
  height: $hamburgerHeight;
  width: $hamburgerHeight;

  &:hover,
  &:focus {
    background-color: $c-primary-shade;
  }
}

.app-settings-nav__contact {
  color: $white;
}

ul {
  padding: 0 0 0 3px;
  margin: 0;
}
li {
  padding: $gap-tiny 0;
  margin: 0;

  // IF we want to do the effects. probably unnecessary.
  // & > * {
  //   opacity: 0;
  //   padding-left: 10px;
  //   transition: all 0.3s ease-in-out, padding 0s linear, opacity 0s linear;

  //   .is-open & {
  //     opacity: 1;
  //     padding-left: 0;
  //     transition: all 0.5s ease-out;
  //   }
  // }
  // @for $i from 1 through 15 {
  //   &:nth-child(#{$i}) > * {
  //     .is-open & {
  //       transition-delay: #{$i * 0.05}s;
  //     }
  //   }
  // }
}
// trap lock
:deep([data-lock]) {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
}

// replace with $disabled-color when it's available
*[disabled] {
  opacity: 0.4;
}
</style>
