import { api } from '@/api/api'
import * as types from '@/store/mutation-types'
import { isDevQA } from '@/utils/environments'

/**
 * module {
 *  id: number,
 *  name: string,
 *  description: string,
 *  enabled: boolean
 * }
 *
 * feature {
 *  id: number,
 *  name: string,
 *  description: string,
 *  enabled: boolean,
 *  modules: {}
 * }
 */

export const FEATURE_CODES = {
  FEATURE_NOT_SPECIFIED: 'no feature specified',
  FEATURE_NOT_FOUND: 'feature not found',
  MODULE_NOT_SPECIFIED: 'no module specified',
  MODULE_NOT_FOUND: 'module not found',
  FEATURE_HAS_NO_MODULES: 'feature has no modules',
  FEATURE_ENABLED: 'feature is enabled',
  FEATURE_DISABLED: 'feature is disabled',
  MODULE_ENABLED: 'module is enabled',
  MODULE_DISABLED: 'module is disabled'
}

let buildFeatures = {}

// Parse the features from the environment variable
if (import.meta.env.VITE_FEATURES) {
  try {
    buildFeatures = JSON.parse(import.meta.env.VITE_FEATURES)
  } catch (error) {
    console.error('Error parsing VUE_APP_FEATURES:', error)
  }
}

const defaultFeatures = {
  DEBUG_VIEW: {
    id: 'DEBUG_VIEW',
    name: 'Debug View',
    description: 'Debug View',
    enabled: true
  },
  api2Project: {
    id: 'api2Project',
    name: 'ProjectAPI2',
    description: 'Project API version 2',
    enabled: false,
    modules: {
      projectSearch: {
        id: 'projectsSearch',
        name: 'Search for projects',
        description: '[POST] method Project/Search on customer API v2',
        enabled: true
      }
    }
  },
  api2Customer: {
    id: 'api2Customer',
    name: 'CustomerAPI2',
    description: 'Customer API version 2',
    enabled: false,
    modules: {
      getUsers: {
        id: 'getUsers',
        name: 'Get Users',
        description: '[GET] method Users on customer API v2',
        enabled: true
      },
      getUserGroups: {
        id: 'getUserGroups',
        name: 'Get User Groups',
        description: '[GET] method User Groups on customer API v2',
        enabled: true
      }
    }
  },
  azureBlobStorage: {
    id: 'azureBlobStorage',
    name: 'Azure Blob Storage',
    description: 'Azure Blob Storage',
    enabled: true,
    modules: {
      uploadFiles: {
        id: 'uploadFiles',
        name: 'Upload Files',
        description: 'Upload files to Azure Blob Storage',
        enabled: true
      },
      viewFiles: {
        id: 'viewFiles',
        name: 'View Files',
        description: 'View files from Azure Blob Storage',
        enabled: true
      },
      deleteFiles: {
        id: 'deleteFiles',
        name: 'Delete Files',
        description: 'Delete files from Azure Blob Storage',
        enabled: true
      },
      updateFiles: {
        id: 'updateFiles',
        name: 'Update Files',
        description: 'Update files from Azure Blob Storage',
        enabled: true
      }
    }
  },
  resetPassword: {
    id: 'resetPassword',
    name: 'Reset Password',
    description: 'Enables the reset-password view and route in Pro',
    enabled: true
  },
  BuyerDashboard: {
    id: 'BuyerDashboard',
    name: 'Buyer Dashboard',
    description: 'Buyer Dashboard and its widgets. (Not hooked up to the view)',
    enabled: true,
    modules: {
      MyTasks: {
        id: 'MyTasks',
        name: 'My Tasks Widget',
        description: '"My Tasks" widget on the buyer dashboard',
        enabled: true
      }
    }
  },
  BuildPublish: {
    id: 'BuildPublish',
    name: 'Build Prepare Publish View',
    description: 'Project View, Build Phase, Prepare Publish view. (Not hooked up to the view)',
    enabled: true,
    modules: {
      UploadFrontEndDocument: {
        id: 'UploadFrontEndDocument',
        name: 'Upload Front End Document',
        description: 'Upload button on the upload Front End Document input',
        enabled: true
      }
    }
  },
  ReportsLibrary: {
    id: 'ReportsLibrary',
    name: 'Reports Library',
    description: 'Reports Library view and reports. (Not hooked up to the view)',
    enabled: true,
    modules: {
      ActiveContractsReport: {
        id: 'ActiveContractsReport',
        name: 'Active Contracts Report',
        description: 'Active Contracts Report',
        enabled: true
      }
    }
  },
  SupplierEvaluationsTable: {
    id: 'SupplierEvaluationsTable',
    name: 'SupplierEvaluationsTable',
    description: 'Supplier Evaluations Table. (Not hooked up to the table)',
    enabled: true,
    modules: {
      ManageColumn: {
        id: 'ManageColumn',
        name: 'ManageColumn',
        description: 'ManageColumn',
        enabled: false
      }
    }
  },
  AppealEvaluationModal: {
    id: 'AppealEvaluationModal',
    name: 'AppealEvaluationModal',
    description: 'Appeal Evaluation Modal.',
    enabled: true,
    modules: {
      EditAppealButton: {
        id: 'EditAppealButton',
        name: 'EditAppealButton',
        description: 'EditAppealButton',
        enabled: false
      }
    }
  },
  RfxEssential: {
    id: 'RfxEssential',
    name: 'RfxEssential',
    description: 'New RFX Essentials Module',
    enabled: true,
    modules: {
      EvaluationSettings: {
        id: 'EvaluationSettings',
        name: 'EvaluationSettings',
        description: 'EvaluationSettings in control panel',
        enabled: false
      }
    }
  },
  ...buildFeatures
}

export const state = {
  features: defaultFeatures
}

export const getters = {
  getFeatures: (state) => state.features,
  getFeature: (state) => (featureId) => state.features[featureId],
  getFeatureModules: (state) => (featureId) => state.features[featureId].modules || {},
  getFeatureEnabled: (state) => (featureId) => {
    if (!featureId) return { enabled: false, message: FEATURE_CODES.FEATURE_NOT_SPECIFIED }
    if (!state.features[featureId])
      return { enabled: false, message: FEATURE_CODES.FEATURE_NOT_FOUND }
    const enabled = state.features[featureId].enabled
    const message = enabled ? FEATURE_CODES.FEATURE_ENABLED : FEATURE_CODES.FEATURE_DISABLED
    return { enabled, message }
  },
  getModuleEnabled: (state) => (featureId, moduleId) => {
    // eslint-disable-next-line prefer-const
    let [feature, module] = featureId.split('.')

    if (moduleId) module = moduleId
    if (!module) return { enabled: false, message: FEATURE_CODES.MODULE_NOT_SPECIFIED }
    if (!feature) return { enabled: false, message: FEATURE_CODES.FEATURE_NOT_SPECIFIED }
    if (!state.features[feature])
      return { enabled: false, message: FEATURE_CODES.FEATURE_NOT_FOUND }
    if (!state.features[feature].enabled)
      return { enabled: false, message: FEATURE_CODES.FEATURE_DISABLED }
    if (!state.features[feature].modules)
      return { enabled: false, message: FEATURE_CODES.FEATURE_HAS_NO_MODULES }
    if (!state.features[feature].modules[module])
      return { enabled: false, message: FEATURE_CODES.MODULE_NOT_FOUND }
    const enabled = state.features[feature].modules[module].enabled
    const message = enabled ? FEATURE_CODES.MODULE_ENABLED : FEATURE_CODES.MODULE_DISABLED
    return { enabled, message }
  }
}

export const actions = {
  async fetchFeatures({ commit }) {
    // get the features list
    // const response = await api.get('/features')
    // const features = await response.json()
    await new Promise((resolve) => setTimeout(resolve, 500))
    const features = {}
    commit(types.FEATURES_SET, { ...defaultFeatures, ...features })
  },
  DANGEROUSLY_SET_FEATURES({ commit, state }, newFeatures) {
    // Honestly, don't do this.
    if (!isDevQA()) {
      return
    }

    const features = { ...state.features, ...newFeatures }

    commit(types.FEATURES_SET, features)
  },
  async DANGEROUSLY_UPDATE_FEATURE_ENABLED({ dispatch }, { featureId, enabled }) {
    // Be careful.
    try {
      await api.post('/features/update', { featureId, enabled })
      await dispatch('fetchFeatures')
    } catch (error) {
      return new Error('Error updating feature enabled status', error)
    }
  }
}

export const mutations = {
  [types.FEATURES_SET](state, features) {
    state.features = features
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
