import FeedItem from '@/views/dashboard/components/dashboard-feed-component/dashboard-feed-component-item.vue'
import Activity from '@/views/dashboard/components/dashboard-item-activity.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'dashboard-feed',
  components: {
    Activity,
    FeedItem
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    type: String,
    max: Number
  },
  computed: {
    ...mapGetters(['getUsers', 'apiRoot']),
    activeComponent() {
      return this.type
    },
    useFeedItem() {
      return this.type !== 'comment' && this.type !== 'activity' && this.type !== 'custom'
    },
    hasActions() {
      return this.$slots.actions
    },
    itemList() {
      return this.max ? this.data.slice(0, this.max) : this.data
    }
  },
  data() {
    return {
      defaultLinks: {
        build: 'settings',
        contract: 'dates',
        source: 'companies'
      },
      viewString: 'ui.views.projects.messages.message-center-view.'
    }
  },
  methods: {
    getUserAvatar(id) {
      const user = this.getUsers.find((u) => u.id === id)
      return user?.avatarUrl || ''
    },
    getUserAvatarUrl(user) {
      if (
        user.avatarUrl === null ||
        user.avatarUrl ===
          `https://service-nst.bidsandtenders.ca/resources/images/avatar/https://ui-avatars.com/api/?size=64&name=${user.firstName}+${user.lastName}`
      )
        return `https://ui-avatars.com/api/?size=64&name=${user.firstName}+${user.lastName}`
      let avatar = user.avatarUrl
      if (avatar.indexOf('/') === -1) {
        avatar = `${this.apiRoot}/resources/images/avatar/${avatar}`
      }
      return this.getUserAvatar(user.id) || avatar
    },
    getProjectLink(params) {
      const phase = this.$t(params.phase.localization).toLowerCase()
      const { id } = params
      const projectLink = `/projects/${id}/${phase}/${this.defaultLinks[phase]}`
      return projectLink
    }
  }
}
